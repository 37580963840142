import "./locales/i18n";


import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import {
  createTheme,
  ThemeProvider,
  styled,
  ThemeOptions,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import Bar from "./components/layout/Bar";

import AttributeCheck from "./components/attributeCheck/AttributeCheck";
import Login from "./components/login/Login";
import RequestInterceptor from "./components/common/RequestInterceptor";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import Button from "@mui/material/Button";
import { useMemo, useState } from "react";
import HomePage from "./pages/home/HomePage";
import {
  ThemeSettings,
  SettingsProvider,
  useSettingsContext,
} from "./components/settings";
import ThemeLocalization from "./locales";
import { useLocales } from "./locales";

type AppProps = {
  pca: IPublicClientApplication;
};

// : { [path: string] : string} = {
export const PageNames = new Map<string, string>();
PageNames.set("/home", "Home");
PageNames.set("/", "Home");
//PageNames.set("/attribute-check", "Attribute Check");

let router = createBrowserRouter([
  {
    id: "Layout",
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to="/home" replace={true} />,
      },
      {
        path: "home",
        element: <HomePage />,
      },
      {
        path: "attribute-check",
        element: <AttributeCheck isExpanded={false} transactionProp={null} />,
        id: "Attribute Check",
      },
    ],
  },
]);

function Copyright(props: any): JSX.Element {
  const { translate } = useLocales();
  
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"© "}{new Date().getFullYear()}{" ICON Plc. "} {`${translate("footer_copyright")}`}
      <p>
        V1.3.9
      </p>
      
    </Typography>
  );
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 100,
  },
}));

function ErrorFallback(props: FallbackProps) {

  
  const { translate } = useLocales();
  
  return (
    <div role="alert">
      <Typography textAlign="center">{`${translate(
                      "error"
                    )}`}</Typography>
      <pre>{props.error.message}</pre>
      <Button onClick={props.resetErrorBoundary} color="primary">
        Try again
      </Button>
    </div>
  );
}

function Layout() {
  const { themeDirection } = useSettingsContext();
  const isAuthenticated = useIsAuthenticated();
  const [hasError, setHasError] = useState(false);

  const themeOptions: ThemeOptions = useMemo(() => {
    return {
      palette: {
        primary: { main: "#128474" },
        secondary: { main: "#094139" },
      },
      direction: themeDirection,
    };
  }, [themeDirection]);

  const mdTheme = createTheme(themeOptions);

  return (
    <DebugRouter>
      <ThemeProvider theme={mdTheme}>
        <ThemeSettings>
          <ThemeLocalization>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <Bar />
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <StyledToolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                  {!isAuthenticated ? (
                    <Login />
                  ) : (
                    <RequestInterceptor>
                      <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={() => setHasError(false)}
                        resetKeys={[hasError]}
                      >
                        {hasError ? null : <Outlet />}
                      </ErrorBoundary>
                    </RequestInterceptor>
                  )}

                  <Copyright sx={{ pt: 4 }} />
                </Container>
              </Box>
            </Box>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </DebugRouter>
  );
}

export function Fallback() {
  
  const { translate } = useLocales();

  return <Typography textAlign="center">{`${translate(
                      "load"
                    )}`}</Typography>;
}

const DebugRouter = ({ children }: { children: any }) => {
  const location = useLocation();
  if (process.env.NODE_ENV === "development") {
    console.log(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
        location.state
      )}`
    );
  }

  return children;
};

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <SettingsProvider>
        <RouterProvider router={router} fallbackElement={<Fallback />} />
      </SettingsProvider>
    </MsalProvider>
  );
}

export default App;
